
// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,700');
// Fonts
@font-face {
  font-family: 'redzone';
  src: url('/fonts/nfl_redzone-webfont.eot');
  src: url('/fonts/nfl_redzone-webfont.eot?iefix') format('eot'),
  url('/fonts/nfl_redzone-webfont.woff') format('woff'),
  url('/fonts/nfl_redzone-webfont.woff2') format('woff2'),
  url('/fonts/nfl_redzone-webfont.ttf') format('truetype'),
  url('/fonts/nfl_redzone-webfont.svg#webfontOkOndcij') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'scoreboard';
  src: url('/fonts/scoreboard.eot');
  src: url('/fonts/scoreboard.eot?iefix') format('eot'),
  url('/fonts/scoreboard.woff') format('woff'),
  url('/fonts/scoreboard.ttf') format('truetype'),
  url('/fonts/scoreboard.svg#webfontOkOndcij') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'colleged';
  src: url('/fonts/colleged-webfont.eot');
  src: url('/fonts/colleged-webfont.eot?iefix') format('eot'),
  url('/fonts/colleged-webfont.woff') format('woff'),
  url('/fonts/colleged-webfont.woff2') format('woff2'),
  url('/fonts/colleged-webfont.ttf') format('truetype'),
  url('/fonts/colleged-webfont.svg#webfontOkOndcij') format('svg');
  font-weight: normal;
  font-style: normal;
}

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "custom";
@import "header";
@import "front";
@import "quiz";
@import "result";
@import "course";
@import "landing-page";
@import "purchase-page";
@import "wonderlic-score-database";
@import "player";
