.header {
  padding-top: 2.3rem;
  padding-bottom: 2rem;
  background: url('../images/vertical-waves.jpg') top left repeat;
  position: relative;

  .mobile-trigger {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 70px;
    cursor: pointer;
    z-index: 102;
    display: none;
    padding: 2rem;
    background: url('../images/vertical-waves.jpg') top left repeat;

    span {
      display: block;
      width: 100%;
      height: 3px;
      background: $white;
      margin-bottom: 4px;
      transform: translateX(0px);
      transition: all 0.15s ease;

      &.last {
        margin-bottom: 0;
      }
    }

    &.open {
      .first,
      .last {
        transform: translateX(-2px);
      }

      .middle {
        transform: translateX(2px);
      }
    }
  }

  nav {
    float: right;

    ul {
      margin: 0;

      li {
        display: inline;
        list-style: none;
        margin-right: 20px;

        &:last-of-type {
          margin-right: 0;
        }

        a {
          font-size: 18px;
          font-weight: 500;
          color: $white;
        }
      }
    }
  }

  .home__link {
    background: url('../images/ribbon.svg') top left no-repeat;
    background-size: contain;
    padding: 5rem 4.7rem;
    box-shadow: 1px -7px 14px rgba(0,0,0,0.2);
    position: relative;
    top: 20px;

    img {
      width: 30px;
      position: relative;
      top: -12px;
    }
  }

  @media all and (max-width: 991px) {
    text-align: center;
    background: transparent;
    padding: 0;
    margin-bottom: 5rem;

    .mobile-trigger {
      display: block;
    }

    .home__link {
      margin: 0 auto;
      display: inline-block;
      margin-bottom: 1rem;
      float: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 142px;
    }

    nav {
      float: none;
      text-align: center;
      position: fixed;
      visibility: hidden;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0px;
      padding: 5rem;
      top: 0;
      z-index: -1;
      opacity: 0;
      background: transparentize($black, 0.1);
      transition: opacity 0.2s ease, z-index 0.2s ease 0.2s;

      ul {
        position: absolute;
        top: 50%;
        transform: translateY(-30%);
        left: 0;
        right: 0;
        padding: 5rem;
        transition: all 0.2s ease;

        li {
          display: block;
          margin: 0 0 20px 0;

          &:hover,
          &:focus {
            ul.sub-nav {
              transform: none;
              transition: none;
            }
          }

          i {
            display: inline-block;
            padding-left: 20px;
            color: $brand-primary;

            &::before {
              display: block;
              transform: rotateZ(0deg);
            }

            &.active::before {
              transform: rotateZ(180deg);
            }
          }

          a {
            display: inline-block !important;
          }

          ul.sub-nav {
            display: none;
            opacity: 1;
            z-index: 1;
            position: relative;
            background: transparent;
            padding: 20px 0 0 0;
            margin: 0;
            box-shadow: none;
            transform: none;
            left: 0;

            &::before {
              display: none;
            }

            &:hover,
            &:focus {
              transform: none;
              transition: none;
            }

            li {
              margin: 0;

              a {
                color: $brand-primary;
              }
            }
          }
        }
      }

      &.open {
        opacity: 1;
        z-index: 100;
        transition: all 0.2s ease;
        visibility: visible;

        ul {
          transform: translateY(-50%);
          transition: all 0.2s ease;
        }
      }
    }
  }
}