.purchase-page {
  .fixed-bg {
    background: #f6f6f6 url('../images/bg-for-web.jpg') center center no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  h1 {
    z-index: 1;
    position: relative;
    color: $white;
    font-weight: bold;
  }

  .score {
    font-size: 12px;
    font-family: $redzone;
    color: $white;

    .number-correct {
      font-size: 22px;
      color: $brand-warning;
    }
  }

  .book-image {
    margin-top: 3rem;
    margin-bottom: 4rem;
    text-align: center;

    img {
      margin: 0 auto;
      width: 80%;
    }
  }

  .download-btn {
    background: $brand-primary;
    color: $white;
    font-weight: bold;
    padding: 2rem;
    border-radius: 4px;
    display: inline-block;
    transition: all 0.2s ease;

    &:hover,
    &:focus {
      background: darken($brand-primary, 10%);
      text-decoration: none;
    }
  }

  .results {
    .result {
      padding: 2rem 4rem 2rem 6rem;
      margin-bottom: 2rem;
      background: $white;
      border-radius: 4px;
      position: relative;

      > .glyphicon {
        position: absolute;
        padding: 1rem;
        top: -1rem;
        right: -1rem;
        color: $white;
        border-radius: 3px;
        box-shadow: 1px 1px 13px rgba(0, 0, 0, 0.05);
        background: $brand-danger;
      }

      &.correct > .glyphicon {
        background: $brand-success;
      }

      .question--number {
        position: absolute;
        top: 2rem;
        left: 2rem;
        font-weight: bold;
        font-size: 18px;
        color: #cacaca;
      }

      .question--title {
        margin-bottom: 30px;

        p {
          margin-bottom: 0;
        }
      }

      .answers {
        list-style: none;
        padding: 0;

        li {
          padding: 1rem;
          border: 1px solid #cacaca;
          border-radius: 4px;
          margin-bottom: 1rem;

          > .glyphicon {
            margin-right: 1rem;
          }

          &.selected {
            background: rgba($brand-danger, 0.1);
            color: $brand-danger;
            border: 1px solid $brand-danger;
          }

          &.correct {
            background: rgba($brand-success, 0.1);
            color: $brand-success;
            border: 1px solid $brand-success;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}