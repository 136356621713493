.landing-page {
  .header {
    position: absolute;
    width: 100%;
    background: transparent;
  }

  .content-wrapper {
    padding-bottom: 60px;
  }

  .hero-wrapper {
    padding-top: 100px;
    padding-bottom: 218px;
    background-image: url("../images/sos.png");

    p {
      font-size: 1.8rem;
      font-weight: 400;
    }
  }

  .teasers-title {
    margin-bottom: 5rem;
  }

  .teasers {
    .teaser {
      display: flex;
      margin-bottom: 5rem;
      box-shadow: 10px 10px 70px rgba(0, 0, 0, 0.08);

      .overview {
        flex: 0 0 33.33333%;
        padding: 5rem;
        background: $brand-primary;
        color: $white;
      }

      .example-question {
        flex: 0 0 66.66667%;
        display: flex;
        align-items: center;
        padding: 5rem;
        position: relative;
        background: $white;
        font-weight: 400;

        ol {
          list-style: lower-alpha;
        }

      }
    }

    .icon {
      display: flex;
      width: 100px;
      height: 100px;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      img {
        width: 75%;
      }
    }
  }

  .pricing-table {
    margin-top: -150px;
    float: none;

    p {
      clear: both;
    }

    .title {
      font-size: 2.4rem;
      padding-bottom: 2rem;
    }
  }

  .price-blocks {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;

    .price-block {
      $radius: 12px;
      flex: 0 0 30%;
      position: relative;
      padding: 2rem 2rem 11.7rem;
      border-radius: 4px;
      background: $white;
      border: 1px solid #eee;
      overflow: hidden;

      &::before {
        position: absolute;
        background: transparentize($gray, .9);
        top: -$radius;
        left: -$radius;
        width: $radius * 2;
        height: $radius * 2;
        display: block;
        border-radius: 50%;
        content: '';
        box-shadow:
                0 0 0 $radius * 2 transparentize($gray, .95);
      }

      &:last-of-type::before {
        background: transparentize($gray, .8);
        box-shadow:
                0 0 0 $radius * 2 transparentize($gray, .95),
                0 0 0 $radius * 4 transparentize($gray, .95),
                0 0 0 $radius * 6 transparentize($gray, .95);
      }

      &.most-popular {
        border: 2px solid $brand-primary;

        &::before {
          background: transparentize($brand-primary, .85);
          box-shadow:
                  0 0 0 $radius * 2 transparentize($brand-primary, .95),
                  0 0 0 $radius * 4 transparentize($brand-primary, .95);
        }

        .tagline {
          position: absolute;
          top: 0;
          right: 0;
          margin: 0 auto;
          padding: .5rem 2rem;
          border-bottom-left-radius: 4px;
          background: $brand-primary;
          color: $white;
          font-size: 12px;
          font-weight: 500;
        }

        h3,
        .price {
          color: $brand-primary;
        }
      }

      .price {
        font-size: 24px;
      }

      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          padding: 1rem .5rem;
          border-bottom: 1px solid #eee;
          font-size: 13px;
        }

        .text-bold {
          font-weight: 500;
        }
      }

      form {
        position: absolute;
        bottom: 2rem;
        left: 0;
        right: 0;

        .stripe-button-el {
          background: transparent;
          border-radius: 4px;
          padding: 0;
          box-shadow: none;
          border: 0;

          span {
            background: $brand-primary;
            border-radius: 4px;
            border: 0;
            box-shadow: none;
            padding: 0.5rem 2rem;
            height: auto;
            transition: all 0.2s ease;

            &:hover,
            &:focus {
              background: darken($brand-primary, 7%);
            }

            &:active {
              background: darken($brand-primary, 10%);
            }
          }
        }
      }
    }
  }

  .about-the-pdf {
    margin-bottom: 5rem;
    font-size: 1.8rem;
  }

  .landing-cta-wrapper {
    padding: 8rem 1rem;
    background: $brand-primary;
    color: $white;
    background: linear-gradient($brand-primary, darken($brand-primary, 10%));
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 120%;
      background: white;
      height: 100px;
      left: -10%;
      top: -50px;
      transform: rotateZ(-2deg);
    }

    h3 {
      font-weight: 100;
      margin-bottom: 2rem;
    }

    button {
      border: 0;
      padding: 2rem 3rem;
      background: $white;
      color: $brand-primary;
      font-weight: 500;
      text-transform: uppercase;
      transition: all 0.2s ease;

      &:hover,
      &:focus {
        box-shadow: 0 11px 24px rgba(0,0,0,0.3);
        transform: translateY(-4px);
      }

      &:active {
        box-shadow: 0 5px 12px rgba(0,0,0,0.3);
      }
    }
  }

  .footer {
    background: darken($brand-primary, 10%);
    color: $white;
  }

  @media all and (max-width: 991px) {
    .hero-wrapper {
      padding-top: 218px;
    }
  }

  @media all and (max-width: 767px) {

    .hero-wrapper {
      text-align: center;
    }

    .pricing-table {
      .title {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .price-blocks {
      flex-wrap: wrap;

      .price-block {
        flex: 0 0 80%;
        margin-bottom: 2rem;
      }
    }

    .teasers {
      .teaser {
        flex-wrap: wrap;
        justify-content: center;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;

        .overview {
          flex: 0 0 100%;
        }

        .example-question {
          flex: 0 0 100%;
        }
      }
    }
  }
}