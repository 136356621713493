.quiz-index-page {
  background: #f6f6f6 url('../images/bg-for-web.jpg') center center no-repeat;
  background-size: cover;

  .quiz-container {
    background: $white;
    border-radius: 4px;
    padding: 4rem 4rem;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 10rem;
  }

  h1 {
    margin-top: 0;
  }
}

#countdown {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  text-align: center;
  border: 0;
  background: $black;
  color: $light-blue;
  font-family: $scoreboard;
  z-index: 10;

  .countdown-amount {
    font-size: 130%;
    display: block;
    padding: 11px 2px;
  }
}

body.quiz {
  padding-top: 44px;

  .quiz-form-row {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .quiz-ad-top,
  .quiz-ad-bottom {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .ad-small {
      display: none;
    }
  }

  .content-wrapper {
    background: #f6f6f6 url('../images/bg-for-web.jpg') center center no-repeat;
    background-size: cover;
  }

  .content {
    padding: 4rem;
    background: $white;
    border-radius: 4px;
    box-shadow: 1px 1px 13px rgba(0,0,0,0.05);
    margin-bottom: 10rem;

    form {
      @include clearfix;
    }

    .form-item {
      margin-bottom: 1rem;

      input[type="radio"] {
        display: none;

        &:checked + label {
          background-color: $dark-grey;
          color: $white;
        }
      }

      input[type="text"] {
        width: 100%;
        padding: 1rem;
        border: 1px solid $dark-grey;
      }

      label {
        width: 100%;
        padding: 1rem;
        border: 1px solid $dark-grey;
        color: $dark-grey;
        text-align: center;
        border-radius: 3px;
        cursor: pointer;
        transition: all 0.15s ease;
      }

      button {
        float: right;
      }
    }

    .question-number {
      text-align: center;
      font-style: italic;
      color: $text-color
    }

    .question-wrapper,
    .question-wrapper p {
      font-size: 18px;
      margin-bottom: 4rem;
      text-align: center;
      color: $text-color;
    }
  }

  @media all and (max-width: 991px) {
    .quiz-ad-bottom,
    .quiz-ad-top {
      .ad-small {
        display: block;
      }

      .ad-large {
        display: none;
      }
    }
  }
}