.course-index-page {
  background: url('../images/playstation-pattern.png');

  .course-container {
    background: $white;
    padding: 4rem;
    border-radius: 4px;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);

    h1 {
      margin-top: 0;
    }

    img {
      margin: 0 auto 4rem auto;
      max-width: 70%;
      display: block;
    }
  }

  .quiz-box-inner {
    padding: 2rem;
    border-radius: 4px;
    border: 1px solid #f0f0f0;
    margin-bottom: 4rem;
    text-align: center;

    h3 {
      margin-top: 0;
    }

    .button-wrapper {
      margin-bottom: 0;

      .quiz-button {
        display: inline-block;
        color: $white;
        background: $brand-success;
        padding: 1rem 3rem;
        border-radius: 3px;
        font-size: 24px;
        text-transform: uppercase;
        transition: all 0.2s ease;
        font-weight: 700;
        letter-spacing: 2px;

        &:hover,
        &:focus {
          background: darken($brand-success, 7%);
          text-decoration: none;
        }

        &.disabled {
          opacity: 0.4;
          cursor: not-allowed;

          &:hover,
          &:focus {
            background: $brand-success;
            text-decoration: none;
          }
        }
      }
    }
  }
}