.front header {
  position: relative;
  z-index: 1;
}

.home-ctas {
  padding: 8rem 2rem;
  position: relative;

  .home-bg {
    background: #f6f6f6 url('../images/bg-for-web.jpg') center center no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 79px; // height of header
    box-sizing: content-box;
    top: -79px;
  }
}

.front .content-wrapper {
  padding-top: 0;
}

.home {
  .container {
    position: relative;
  }

  h1 {
    text-align: center;
    font-size: 14px;
    line-height: 28px;
    color: $white;
    font-weight: 100;
    font-family: $redzone;
    text-shadow: 2px 2px 7px rgba(0,0,0,0.4);
    z-index: 2;
  }

  .teaser-copy {
    padding: 3rem 0;
  }

  .tagline {
    text-align: center;
    font-weight: 400;
    max-width: 500px;
    margin: 0 auto;
  }

  .disclaimer {
    position: relative;
    text-align: center;
    color: $white;
    font-size: 10px;
  }

  .about-the-test {
    border-top: 5px solid $white;
    background: url('../images/grass-pattern-sm.jpg') top left;
    padding: 8rem 2rem;
  }

  .about-the-test__content {
    background: rgba(0,0,0,0.6);
    padding: 6rem 6rem;
    text-align: center;

    h2 {
      font-family: $colleged;
      color: $white;
      font-size: 52px;
      text-align: center;
      margin-bottom: 4rem;
      margin-top: 0;
    }

    p {
      color: $white;
    }

    .btn-take-quiz {
      display: inline-block;
      color: #373737;
      background: $brand-warning;
      padding: 1.3rem 3rem 1rem;
      border-radius: 0;
      font-size: 12px;
      text-transform: uppercase;
      transition: all 0.2s ease;
      letter-spacing: 2px;
      font-family: $redzone;
      margin: 0 auto;
      float: none;

      &:hover,
      &:focus {
        background: #373737;
        color: $brand-warning;
        text-decoration: none;
      }
    }
  }

  .stats {
    padding: 8rem 2rem;
    background: url('../images/stats-bg.jpg') center center;
    background-size: cover;
    border-top: 5px solid $white;
  }

  .stats__content {
    color: $white;

    h3 {
      font-family: $colleged;
      font-size: 32px;
      margin-top: 6rem;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}

.quiz-cta-container {
  display: flex;
  flex: 0 0 100%;
  justify-content: space-between;
  padding: 4rem 1rem;

  .quiz-cta {
    flex: 0 0 30%;
    text-align: center;
    padding: 3rem 1rem;
    height: 280px;
    position: relative;
    box-shadow: 1px 1px 13px rgba(0,0,0,0.05);
    background-color: rgba(145,148,147,0.9);

    &.ad-container {
      padding: 2rem;
      background: url('/images/sports.png') top left repeat;
      background-size: 240px;
      color: #373737;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex: 0 0 320px;

      h3 {
        margin-top: 0;
        font-weight: bold;
        font-size: 18px;
      }

      img {
        width: 170px;
        margin-top: -60px;
        margin-bottom: 20px;
      }

      a {
        background: #3097D1;
        padding: 1rem;
        color: #fff;
      }
    }
  }

  .quiz-cta__title {
    font-size: 32px;
    line-height: 24px;
    width: 100%;
    font-family: $roboto-slab;
    font-weight: bold;
    color: $brand-warning;

    .subtitle {
      font-size: 14px;
      font-weight: 300;
      color: $white;
      font-family: $font-family-sans-serif;
      font-style: italic;
    }
  }

  .quiz-cta__body {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    a {
      display: block;
      color: #373737;
      background: $brand-warning;
      padding: 1.3rem 3rem 1rem;
      border-radius: 0;
      font-size: 12px;
      text-transform: uppercase;
      transition: all 0.2s ease;
      letter-spacing: 2px;
      font-family: $redzone;

      &:hover,
      &:focus {
        background: #373737;
        color: $brand-warning;
        text-decoration: none;
      }
    }
  }

  .quiz-cta__icon {
    img {
      height: 50px;
    }
  }

  @media all and (max-width: 991px) {
    flex-wrap: wrap;
    .quiz-cta {
      flex: 0 0 45%;
      &:first-of-type {
        order: 2;
      }
      &:last-of-type {
        order: 3;
      }
      &.ad-container {
        order: 1;
        flex: 0 0 100%;
        height: auto;
        margin-bottom: 4rem;
        justify-content: space-around;
      }
    }
  }

  @media all and (max-width: 615px) {
    flex-wrap: wrap;

    .quiz-cta {
      flex: 0 0 100%;
      margin-bottom: 2rem;
    }
  }
}

@media all and (max-width: 991px) {
  .home .about-the-test__content h2 {
    font-size: 32px;
  }

  .home .about-the-test__content .btn-take-quiz {
    font-size: 9px;
    letter-spacing: 0;
  }
}