.wonderlic-score-database {
  .fixed-bg {
    background: #f6f6f6 url('../images/bg-for-web.jpg') center center no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .table-container {
    margin-bottom: 4rem;
    padding: 6rem;
    background: rgba(243, 243, 243, 0.9);
  }

  .dataTables_filter {
    display: block;
    width: 100%;
    margin-bottom: 1rem;

    label {
      display: block;
      text-align: left;

      input {
        display: block;
        width: 100%;
        border: 0;
        padding: 1rem;
        margin: 0;
      }
    }
  }

  h1 {
    margin-top: 0;
  }

  table.dataTable tbody tr {
    background: transparent;
  }

  @media all and (max-width: 600px) {
    .table .hide-on-mobile {
      display: none;
    }
  }
}