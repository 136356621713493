.player-page {
  .fixed-bg {
    background: #f6f6f6 url('../images/bg-for-web.jpg') center center no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .player-container {
    margin-bottom: 4rem;
    padding: 4rem 6rem;
    background: rgba(243, 243, 243, 0.9);
  }

  .quiz-cta {
    padding: 4rem 6rem;
    width: 80%;
    margin: 0 auto;
    position: relative;
    top: -80px;
    background: #333;
    color: #fff;
    text-align: center;
    background: url(../images/vertical-waves.jpg) top left repeat;
    box-shadow: 2px -2px 14px rgba(0,0,0,0.3);

    h3 {
      margin-top: 0;
      margin-bottom: 2rem;
    }
  }

  .player-card {
    .caption {
      background: #f1f1f1;
      padding: 2rem 1rem;
      text-align: center;
      margin-top: -2px;
      color: $black;
      font-weight: bold;
    }
  }
}