html, body {
  background-color: #111;
  color: #636b6f;
  font-weight: 400;
  height: 100vh;
  margin: 0;
}

body {
  min-height: 100%;
  height: auto;
  position: relative;

  &.cookie-banner {
    padding-top: 39px;
  }
}

img {
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $roboto-slab;
}

.contact-page {
  .fixed-bg {
    background: #f6f6f6 url('../images/bg-for-web.jpg') center center no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .contact-form-wrapper {
    margin-bottom: 4rem;
    padding: 6rem;
    background: rgba(243, 243, 243, 0.9);

    h1 {
      margin-top: 0;
    }
  }
}

.default-template {
  .fixed-bg {
    background: #f6f6f6 url('../images/bg-for-web.jpg') center center no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .content-container .col-md-8 {
    margin-bottom: 4rem;
    padding: 6rem;
    background: rgba(243, 243, 243, 0.9);
    position: relative;
  }
}

.contest-box {
  background: url('/images/sports.png') top left repeat;
  background-size: 240px;
  color: #373737;
  padding: 3rem;
  text-align: center;
  position: relative;
  margin-bottom: 3rem;
}

.contest-box__content {
  position: relative;

  h2 {
    font-weight: bold;
  }

  .terms-link {
    color: #373737;
    font-size: 10px;
    text-decoration: underline;
  }
}

.content-wrapper {
  padding-bottom: 128px;
  padding-top: 10rem;
  line-height: 24px;

  p {
    margin-bottom: 30px;
    font-size: 15px;
  }

  .above-content-ad-space,
  .below-content-ad-space {
    .mobile {
      display: none;
    }

    .full-width {
      display: block;
    }

    @media all and (max-width: 800px) {
      .mobile {
        display: block;
      }

      .full-width {
        display: none;
      }
    }
  }
}

.inner-container {
  max-width: 1170px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.alert p {
  margin: 0;
  text-align: center;
}

.subnav {
  padding: 20px 15px;
  background: #111;

  .menu {
    padding: 1rem;
    margin: 0;
    background: transparent;
    border-radius: 4px;

    li {
      list-style: none;
      padding: 1rem 0;
      border-bottom: 1px solid #212121;
      line-height: 1.4;

      &:last-of-type {
        border-bottom: 0;
      }

      &.active a {
        font-weight: 500;
      }

      a {
        color: #a9a9a9;
      }
    }
  }
}

.fbiq-button {
  display: inline-block;
  color: #373737;
  background: $brand-warning;
  padding: 1.3rem 3rem 1rem;
  border-radius: 0;
  font-size: 12px;
  text-transform: uppercase;
  transition: all 0.2s ease;
  letter-spacing: 2px;
  font-family: $redzone;
  margin: 0 auto;
  float: none;
  text-align: center;
  border: 0;

  &:hover,
  &:focus {
    background: #373737;
    color: $brand-warning;
    text-decoration: none;
  }
}

.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 12px;
  padding-top: 5rem;
  padding-bottom: 2rem;
  background: #111;
  color: #525151;

  h4 {
    font-size: 16px;
  }

  a {
    color: #525151;
    text-decoration: underline;
  }

  .disclaimer {
    margin-top: 3rem;
  }
}

.cookie-consent {
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  background: $brand-primary;
  left: 0;
  right: 0;
  padding: 1rem;
  color: $white;
  font-size: 12px;
  z-index: 10000;
}

.cookie-consent__agree {
  font-weight: normal;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  color: $white;
  width: 100%;
  background: transparent;
  text-align: right;
  padding: 1rem 3rem;
  cursor: pointer;
  border: 0;
}

.checkout-page {
  background: url('/images/bg-for-web.jpg');
  background-size: cover;

  .alert {
    margin-top: 2rem;
    text-align: center;

    p {
      margin-bottom: 0;
    }
  }

  .content {
    background: $white;
    padding: 4rem;
    border: 1px solid #eee;
    overflow: hidden;
    margin-bottom: 2rem;
  }

  .product-info {
    background: $white;
    padding: 2rem 4rem;
    border: 1px solid #eee;
    margin-bottom: 4rem;

    p {
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      font-weight: bold;

      .price {
        font-weight: normal;
      }
    }
  }

  fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0;
    max-width: 500px;

    label {
      margin-bottom: -1px;
      display: flex;
      align-items: center;
      border: 1px solid #eeeeee;
      padding: 7px 0 7px 20px ;
      background: #fff;
      //border-radius: 3px;
      //box-shadow: 5px 5px 13px rgba(0, 0, 0, 0.05);

      span {
        flex: 0 0 100px;
      }

      input {
        width: 100%;
        border: 0;
        padding-right: 20px;
        font-weight: normal;

        &::-webkit-input-placeholder {
          color: #aab7c4;
          font-weight: normal;
        }
        &::-moz-placeholder {
          color: #aab7c4;
          font-weight: normal;
        }
        &:-ms-input-placeholder {
          color: #aab7c4;
          font-weight: normal;
        }
      }

      select {
        width: 100%;
        flex: 1;
        border-style: none;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: #313b3f;
        cursor: pointer;
        background: transparent;
      }
    }
  }

  .stripe-card,
  .country {
    width: 100%;
  }

  .StripeElement {
    flex: 1;
  }

  .payment-info {
    margin-bottom: 2rem;
  }

  .pay-with-stripe {
    display: block;
    margin-top: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: $brand-primary;
    color: $white;
    padding: 12px 20px;
    border: 0;
    font-family: $headings-font-family;
    font-weight: normal;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover,
    &:focus {
      background: darken($brand-primary, 7%);
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;

      &:hover,
      &:focus {
        background: $brand-primary;
      }
    }
  }
}

.buy-wrapper {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 70%;
  margin: 0 auto;

  p {
    margin-bottom: 0;
  }

  .buy-button {
    background: $brand-primary;
    padding: 1rem 3rem;
    display: inline-block;
    color: $white;
    border-radius: 2px;
    box-shadow: 2px 2px 9px transparentize($brand-primary, .7);
    transition: all 0.2s ease;

    &:hover,
    &:focus {
      text-decoration: none;
      background: darken($brand-primary, 7%);
      box-shadow: 1px 1px 4px transparentize($brand-primary, .7);
    }
  }
}

@media all and (max-width: 991px) {
  .content-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .subnav {
    margin-bottom: 4rem;
  }
}